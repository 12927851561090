import { Attribute } from "./attribute";
import { Collection } from "./collection";
import { FabricObject } from "./fabric";
import { Finish } from "./finish";
import { PriceUmbrella } from "../pricing/price";

export interface UmbrellaModel {
  _id: string;
  model: string;
  display_name: string;
  finishes: Finish[];
  dv?: boolean;
  size?: string;
  shape?: string;
  line_art_url?: string;
  product_lines?: Collection;
  price?: PriceUmbrella;
  image?: string;
  attributes: any;
  specs: {handle: string, display_name: string, value: string, unit: string}[];
}

export enum UmbrellaType {
  Market = "market",
  Giant = "giant",
  Cantilever = "cantilever",
  Beach = "beach",
  Patio = "patio"
}
