import * as option from "@/models/configurator/configurator_options";

import { Finish } from "@/models/products/finish";
import { FrameState } from "./types";

export const setFinish = (state: FrameState, value: Finish) => {
  state.finish = value;
};

export const setFinial = (state: FrameState, value: option.Finial) => {
  state.finial = value;
};

export const setFinialFinish = (state: FrameState, value: Finish) => {
  state.finialFinish = value;
};

export const setBottomPole = (
  state: FrameState,
  value: option.BottomPole | null
) => {
  state.bottomPole = value;
};
