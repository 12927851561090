















































import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component
export default class Share extends Vue {
  @Prop({ default: false }) showDialog!: boolean;
  @Prop() shareLink!: string;
  protected show = false;
  protected copied = false;

  @Watch("showDialog")
  protected isOpen(): void {
    this.show = this.showDialog;
    this.copied = false;
  }

  protected closeDialog(): void {
    this.$emit('close');
  }

  protected copy(): void {
    this.$nextTick(() => {
      const selectEl = this.$refs.linkElement as HTMLInputElement;
      selectEl.select();
      document.execCommand("copy");
      this.copied = true;
    });
  }
}
