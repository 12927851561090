






















































































































































import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { LayoutStore, ShapeStore } from "@/mixins/store";
import Pricing from "@/components/order_form/Pricing.vue";
import Share from "@/components/share/Share.vue";
import ConfigFileService from "@/services/config_file_service";
import { ConfigCreator } from "@/mixins/configurator";
import {
  APIError,
  AuthError,
  BadRequest,
  GenericError
} from "@/services/error_service";
import Download from "@/components/download/Download.vue";
import { EventBus } from "@/event-bus";
import { Auth } from "@/mixins/auth";
import { Roles } from "@/models/user/roles";
import QuickShip from "@/views/QuickShip.vue";
import UIkit from "uikit";
import { ConfigType } from "@/models/configurator/config_file";
import { Preset } from "@/models/configurator/configurator_options";
import LeadTime from "@/components/order_form/LeadTime.vue";
import AuthService from "@/services/auth_service";
@Component({
  components: {
    Pricing,
    Share,
    Download,
    QuickShip,
    LeadTime
  }
})
export default class Footer extends mixins(
  ShapeStore,
  LayoutStore,
  ConfigCreator,
  Download,
  Auth
) {
  protected configFileService = new ConfigFileService();
  protected shareLink = "";
  protected showShareDialog = false;
  protected loader: any = null;
  protected Roles = Roles;
  protected Preset = Preset;
  protected authService = new AuthService();

  protected closeShare(): void {
    this.showShareDialog = false;
  }

  public async logout(): Promise<void> {
    try {
      this.setLoggedIn(false);
      this.setUserRole(null);
      await this.authService.logout();
      this.$router.push("/");
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        GenericError.popup(err.message);
      }
    }
  }

  protected async getImagesShare(): Promise<void> {
    this.loader = this.$loading.show(
      { opacity: 1 },
      { before: this.$createElement("h1", "Generating share link...") }
    );
    EventBus.$emit("getStaticImages", this.share);
  }

  protected async getImagesQuickShip(): Promise<void> {
    this.loader = this.$loading.show(
      { opacity: 1 },
      { before: this.$createElement("h1", "Generating quick ship record...") }
    );
    EventBus.$emit("getStaticImages", this.makeQuickShip);
  }

  protected async getImagesQuote(): Promise<void> {
    this.loader = this.$loading.show({ opacity: 1 });
    EventBus.$emit("getStaticImages", this.getQuote);
  }

  protected getQuote(images: string[]): void {
      this.loader.hide();
      this.$router.push({
        name: "RequestQuote",
        params: {
          img1: images[0],
          img2: images[1],
          img3: images[2]
        }
      });
  }

  protected async makeQuickShip(images: string[]): Promise<void> {
    try {
      const data = await this.transformDataToConfigFile();
      const body = { ...data, type: ConfigType.QS, image: images[0] };
      const res = await this.configFileService.createFile(body);
      this.shareLink = process.env.VUE_APP_SHARE_LINK + "?design=" + res._id;
      this.loader.hide();
      UIkit.notification({
        message: `QuickShip file created, link: ${this.shareLink}`
      });
    } catch (err) {
      if (err instanceof BadRequest) {
        BadRequest.popup(err.message, err.statusCode);
      } else {
        APIError.popup(err.message, err.statusCode);
      }
      setTimeout(() => {
        this.loader.hide();
      }, 1000);
    }
  }

  protected async share(images: string[]): Promise<void> {
    try {
      const data = await this.transformDataToConfigFile();
      const body = { ...data, image: images[1] };
      const res = await this.configFileService.createShareFile(body);
      this.shareLink =
        process.env.VUE_APP_SHARE_LINK + "?design=" + res.share_id; //TODO
      setTimeout(() => {
        this.loader.hide();
        this.showShareDialog = true;
      }, 1000);
    } catch (err) {
      if (err instanceof BadRequest) {
        BadRequest.popup(err.message, err.statusCode);
      } else {
        APIError.popup(err.message, err.statusCode);
      }
      setTimeout(() => {
        this.loader.hide();
      }, 1000);
    }
  }

  protected formatTitle(title: string, part: string): string {
    let result = "";
    if (title.indexOf(" ")) {
      if (part == "a") {
        result = title.substr(0, title.indexOf(" "));
      } else {
        result = title.substr(title.indexOf(" ") + 1);
      }
    } else {
      return title;
    }

    return result;
  }

  protected stepForward(): void {
    switch (this.$route.name) {
      case "Layout":
        this.$router.push("/designer/shape");
        break;
      case "Shape":
        this.$router.push("/designer/canopy");
        break;
      case "Canopy":
        this.$router.push("/designer/frame");
        break;
      case "Frame":
        this.$router.push("/designer/base");
        break;
      case "Base":
        this.$router.push("/designer");
        break;
    }
  }

  protected stepBack(): void {
    switch (this.$route.name) {
      case "Layout":
        this.$router.push("/designer/base");
        break;
      case "Shape":
        this.$router.push("/designer");
        break;
      case "Canopy":
        this.$router.push("/designer/shape");
        break;
      case "Frame":
        this.$router.push("/designer/canopy");
        break;
      case "Base":
        this.$router.push("/designer/frame");
        break;
    }
  }
  get prevLabel(): string {
    let label = "";
    switch (this.$route.name) {
      case "Layout":
        label = "Base";
        break;
      case "Shape":
        label = "Layout";
        break;
      case "Canopy":
        label = "Shape & Size";
        break;
      case "Frame":
        label = "Canopy";
        break;
      case "Base":
        label = "Frame";
        break;
    }
    return label;
  }

  get nextLabel(): string {
    let label = "";
    switch (this.$route.name) {
      case "Layout":
        label = "Shape & Size";
        break;
      case "Shape":
        label = "Canopy";
        break;
      case "Canopy":
        label = "Frame";
        break;
      case "Frame":
        label = "Base";
        break;
      case "Base":
        label = "Layout";
        break;
    }
    return label;
  }
}
