import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import base from "./modules/configurator/base";
import canopy from "./modules/configurator/canopy";
import frame from "./modules/configurator/frame";
import global from "./modules/global";
import layout from "./modules/configurator/layout";
import shape from "./modules/configurator/shape";
import user from "./modules/user";
const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  key: "shadeDesigner"
});

Vue.use(Vuex);

export default new Vuex.Store({
  state: undefined,
  mutations: {},
  actions: {},
  modules: {
    global,
    user,
    canopy,
    base,
    frame,
    layout,
    shape
  },
  plugins: [vuexLocal.plugin]
});
