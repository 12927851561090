import { FinishHandle } from "@/models/products/finish";
import _Vue from "vue";
import * as option from "@/models/configurator/configurator_options";
import { CustomUmbrella } from "@/models/configurator/custom_umbrella";
import { ConfigFile, StartAppFile } from "@/models/configurator/config_file";

// 2. Specify a file with the types you want to augment
//    Vue has the constructor type in types/vue.d.ts
declare module "vue/types/vue" {
  // 3. Declare augmentation for Vue
  interface Vue {
    $viewer: ViewerWindow;
  }
}
declare module "vue/types/vue" {
  // Global properties can be declared
  // on the `VueConstructor` interface
  interface VueConstructor {
    $viewer: ViewerWindow;
  }
}

declare global {
  interface ViewerWindow extends Window {
    AddNewStyleRule: (css: string) => Promise<void>;
    ChangeCanopyLayout: (layout: option.Preset) => Promise<void>;
    ChangeSize: (model: string) => Promise<void>;
    ChangeVent: (type: "single" | "double") => Promise<void>;
    ChangeCanopyFabric: (
      scale: number,
      fabricCode: string,
      scale2?: number,
      fabricCode2?: string
    ) => Promise<any>;
    ChangeCanopyTrim: (
      fabricCodeOuter: string | null,
      fabricCodeInner: string | null | undefined
    ) => Promise<any>;
    ChangeTopVentFabric: (scale: number, fabricCode: string) => Promise<void>;
    ChangeDblVentFabric: (
      scale: number,
      fabricCode: string,
      scale2?: number,
      fabricCode2?: string
    ) => Promise<void>;
    ChangeVentTrim: (
      fabricCode: string | null,
      fabricCode2?: string
    ) => Promise<any>;
    ChangeValance: (type: option.Valance) => Promise<void>;
    ChangeValanceFabric: (
      scale?: number | null,
      fabricCode?: string | null,
      scale2?: number | null,
      fabricCode2?: string | null
    ) => Promise<void>;
    ChangeBindingFabric: (bindingCode: string | null) => Promise<void>;
    ChangeRibAttachment: (
      type: option.Rib,
      fabricCode?: string
    ) => Promise<void>;
    ChangeFrameFinish: (finish: FinishHandle) => Promise<void>;
    ChangeFinial: (type: option.Finial, finish?: FinishHandle) => Promise<void>;
    ChangeBottomPole: (type: option.BottomPole) => Promise<void>;
    ChangeBase: (
      model?: string | null,
      finish?: FinishHandle | null,
      stem?: option.Stem | null
    ) => Promise<void>;
    ChangeVentType: (type: "single" | "double") => Promise<void>;
    GoToCamera: (cameraPosition: 0 | 1 | 2 | 3) => Promise<void>;
    RenderStaticImage: (resolution?: {
      x: number;
      y: number;
    }) => Promise<string[]>;
    RenderCurrentView: (resolution?: {
      x: number;
      y: number;
    }) => Promise<string>;
    SetAssetPath: (assetPath: string) => Promise<void>;
    StartApp: (jsonData: StartAppFile) => Promise<void>;
    TiltUmbrella: (tilt: boolean) => Promise<void>;
    ToggleVent: (toggle: boolean) => Promise<void>;
    ToggleValance: (toggle: boolean) => Promise<void>;
  }
}

const ViewerPlugin = {
  install(Vue: typeof _Vue, data: CustomUmbrella | null = null): void {
    Vue.prototype.$viewer = (document.getElementById(
      "viewer"
    ) as HTMLFrameElement)!.contentWindow as ViewerWindow;
  },
  init(Vue: typeof _Vue, data: CustomUmbrella | null = null): void {
    Vue.prototype.$viewer = (document.getElementById(
      "viewer"
    ) as HTMLFrameElement)!.contentWindow as ViewerWindow;
  }
};

export default ViewerPlugin;
