






























import { Component } from "vue-property-decorator";

import { mixins } from "vue-class-component";

import {
  LayoutStore,
  CanopyStore,
  FrameStore,
  BaseStore,
  ShapeStore
} from "@/mixins/store";

import { Auth } from "@/mixins/auth";

import { ConfigCreator } from "@/mixins/configurator";

@Component
export default class StartOverModal extends mixins(
  LayoutStore,
  CanopyStore,
  FrameStore,
  BaseStore,
  ShapeStore,
  Auth,
  ConfigCreator
) {
  /**

   * Erase all values in store & redirect to first tab

   */

  protected goToMenu(): void {
    this.addFileBeingEditedId("");
    const loader = this.$loading.show(
      { opacity: 1 },

      { before: this.$createElement("h1", "Redirecting to setup wizard...") }
    );
    setTimeout(() => {
      loader.hide();
      this.$router.push("/collectionmenu");
    }, 1000);
  }

  protected async startOver(): Promise<void> {
    this.addFileBeingEditedId("");
    const loader = this.$loading.show(
      { opacity: 1 },
      { before: this.$createElement("h1", "Resetting your umbrella...") }
    );
    const defaultFile = this.collection.config_file![0];
    await this.transformConfigFileAndSaveToStore(defaultFile);
    await this.transformDataToViewerJson();
    setTimeout(() => {
      loader.hide();
      this.$router.go(0);
    }, 500);
  }
}
