import * as option from "@/models/configurator/configurator_options";

import { CanopyGetters, CanopyState } from "./types";
import { FabricSwatch, FixedFabricArray } from "@/models/products/fabric";

import { CustomUmbrella } from "@/models/configurator/custom_umbrella";
import { StoreModule } from "@/store/types";

export const getMainCanopy = (state: CanopyState): option.Panel => {
  return state.mainCanopy;
};

export const getMainFabric = (state: CanopyState): FixedFabricArray => {
  return state.mainFabric;
};

export const getVentTopFabric = (state: CanopyState): FixedFabricArray | [] => {
  return state.ventTopFabric;
};

export const getVentMiddle = (state: CanopyState): option.Panel | null => {
  return state.ventMiddle;
};

export const getVentMiddleFabric = (
  state: CanopyState
): FixedFabricArray | [] => {
  return state.ventMiddleFabric;
};

export const getValanceFabric = (state: CanopyState): FixedFabricArray | [] => {
  return state.valanceFabric;
};

export const getValance = (state: CanopyState): option.Valance => {
  return state.valance;
};

export const getBindingFabric = (state: CanopyState): FabricSwatch | null => {
  return state.bindingFabric;
};

export const getRib = (state: CanopyState): option.Rib | null => {
  return state.rib;
};

export const getRibFabric = (state: CanopyState): FabricSwatch | null => {
  return state.ribFabric;
};

export const getMainTrimFabric = (state: CanopyState): FabricSwatch | null => {
  return state.mainTrimFabric;
};

export const getMainTrimFabricInner = (
  state: CanopyState
): FabricSwatch | null => {
  return state.mainTrimFabricInner;
};

export const getVentTrimFabric = (state: CanopyState): FabricSwatch | null => {
  return state.ventTrimFabric;
};

export const getAllCanopyData = (
  state: CanopyState,
  getters: any,
  rootState: any,
  rootGetters: any
): CustomUmbrella["canopy"] => {
  const canopy = {} as CustomUmbrella["canopy"];
  canopy.mainCanopy =
    rootGetters[StoreModule.Canopy + CanopyGetters.GetMainCanopy];
  canopy.mainFabric =
    rootGetters[StoreModule.Canopy + CanopyGetters.GetMainFabric];
  canopy.ventTopFabric =
    rootGetters[StoreModule.Canopy + CanopyGetters.GetVentTopFabric];
  canopy.ventMiddle =
    rootGetters[StoreModule.Canopy + CanopyGetters.GetVentMiddle];
  canopy.ventMiddleFabric =
    rootGetters[StoreModule.Canopy + CanopyGetters.GetVentMiddleFabric];
  canopy.valance = rootGetters[StoreModule.Canopy + CanopyGetters.GetValance];
  canopy.bindingFabric =
    rootGetters[StoreModule.Canopy + CanopyGetters.GetBindingFabric];
  canopy.rib = rootGetters[StoreModule.Canopy + CanopyGetters.GetRib];
  canopy.ribFabric =
    rootGetters[StoreModule.Canopy + CanopyGetters.GetRibFabric];
  canopy.mainTrimFabric =
    rootGetters[StoreModule.Canopy + CanopyGetters.GetMainTrimFabric];
  canopy.mainTrimFabricInner =
    rootGetters[StoreModule.Canopy + CanopyGetters.GetMainTrimFabricInner];
  canopy.ventTrimFabric =
    rootGetters[StoreModule.Canopy + CanopyGetters.GetVentTrimFabric];

  return canopy;
};
