












import { Component, Vue } from "vue-property-decorator";
import ViewerPlugin from "@/plugins/viewer";
import { ConfigCreator } from "@/mixins/configurator";
import { mixins } from "vue-class-component";
import { GenericError, ViewerError } from "@/services/error_service";
import { EventBus } from "@/event-bus";
import { Stem } from "@/models/configurator/configurator_options";

@Component
export default class Configurator extends mixins(ConfigCreator) {
  created() {
    EventBus.$on(
      "getStaticImages",
      async (callback: (images: string[]) => Promise<any>) => {
        const images = await this.getImages();
        callback(images);
      }
    );
  }

  protected async frameLoaded(): Promise<void> {
    ViewerPlugin.init(Vue);
    try {
      const data = await this.transformDataToViewerJson();
      await this.$viewer.SetAssetPath(process.env.VUE_APP_CDN_URL + "/sd");
      /** Exception logic for bases that include 18ST2 stems in the base's model name. These are specific to giant umbrellas, which MUST have an 18ST2 stem
       */
      if (data.base.base && data.base.base !== Stem.Stem18ST2 && data.base.base.indexOf("18ST2") > -1) {
        data.base.base = data.base.base.slice(0, -6);
        data.base.baseStem = Stem.Stem18ST2;
      }
      await this.$viewer.StartApp(data);
      EventBus.$emit("ready");
      this.$emit("ready");
    } catch (err) {
      ViewerError.redirect(err);
    }
  }

  protected async getImages(resolution?: {
    x: number;
    y: number;
  }): Promise<string[]> {
    let images: string[] = [];
    try {
      let res;
      if (resolution) {
        res = await this.$viewer.RenderStaticImage(resolution);
      } else {
        res = await this.$viewer.RenderStaticImage({ x: 600, y: 600 });
      }
      if (res) {
        images = res;
      } else {
        throw new Error("Error obtaining images");
      }
    } catch (err) {
      GenericError.popup(err.message);
    }
    return images;
  }
}
