


























import { Component, Prop } from "vue-property-decorator";
import Gallery from "@/components/gallery/Gallery.vue";
import CameraControls from "@/components/designer/icon_menu/CameraControls.vue";
import { mixins } from "vue-class-component";
import { CanopyStore, LayoutStore, ShapeStore } from "@/mixins/store";
import { Roles } from "@/models/user/roles";
import { GenericError } from "@/services/error_service";
import { Auth } from "@/mixins/auth";
import SaveUmbrella from "@/components/designer/icon_menu/SaveUmbrella.vue";
@Component({
  components: {
    Gallery,
    CameraControls,
    SaveUmbrella,
  },
})
export default class DesignerIconMenu extends mixins(
  LayoutStore,
  CanopyStore,
  ShapeStore,
  Auth
) {
  protected Roles = Roles;
  @Prop({ default: false}) tiltAllowed!: boolean;

  protected tilt(): void {
    this.$emit('tiltUmbrella');
  }

  protected async changeCamera(camPosition: 0 | 1 | 2 | 3): Promise<void> {
     await this.$viewer.GoToCamera(camPosition);
  }

  protected async getImage(resolution?: {
    x: number;
    y: number;
  }): Promise<void> {
    try {
      let res;
      if (resolution) {
        res = await this.$viewer.RenderCurrentView(resolution);
      } else {
        res = await this.$viewer.RenderCurrentView({ x: 600, y: 600 });
      }
      if (res) {
        this.forceDownloadImage(res);
      } else {
        throw new Error("Error obtaining spec sheet images");
      }
    } catch (err) {
      throw new GenericError(err);
    }
  }

  protected forceDownloadImage(image: string): void {
    const date = new Date().toLocaleDateString("en-US").replace(/\//g, "-");
    const link = document.createElement("a");
    link.href = image;
    link.setAttribute(
      "download",
      `Frankford_Umbrella_${date}_${this.mainFabric[0].name}.png`
    );
    document.body.appendChild(link);
    link.click();
  }
}
