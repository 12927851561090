/**
 * Globally registered components
 *
 * These components can be used anywhere and do not need to be imported into individual files
 *
 */

import InfoModal from "@/components/designer/reusable/modal/InfoModal.vue";
import Stock from "@/components/designer/Stock.vue";
import Clear from "@/components/designer/reusable/Clear.vue";
import StartOverModal from "@/components/designer/reusable/modal/StartOverModal.vue";

import AddFabricIcon from "@/assets/images/add-fabric.svg?inline";
import CloseIcon from "@/assets/images/icon-close.svg?inline";
import CloseIconWhite from "@/assets/images/icon-close-white.svg?inline";
import FilterIcon from "@/assets/images/icon-filter.svg?inline";
import InfoIcon from "@/assets/images/icon-info.svg?inline";
import Logo from "@/assets/images/logo.svg?inline";
import SearchIcon from "@/assets/images/icon-search.svg?inline";
import StartOverIcon from "@/assets/images/icon-start-over.svg?inline";
import ClearIcon from "@/assets/images/icon-close.svg?inline";
import WarningIcon from "@/assets/images/icon-warning.svg?inline";
import PlusIcon from "@/assets/images/icon-plus.svg?inline";
import MinusIcon from "@/assets/images/icon-minus.svg?inline";

import RadioInput from "@/components/designer/reusable/RadioInput.vue";

import Vue from "vue";

Vue.component("InfoIcon", InfoIcon);
Vue.component("AddFabricIcon", AddFabricIcon);
Vue.component("SearchIcon", SearchIcon);
Vue.component("FilterIcon", FilterIcon);
Vue.component("CloseIcon", CloseIcon);
Vue.component("CloseIconWhite", CloseIconWhite);
Vue.component("StartOverIcon", StartOverIcon);
Vue.component("Logo", Logo);
Vue.component("InfoModal", InfoModal);
Vue.component("StartOverModal", StartOverModal);
Vue.component("ClearIcon", ClearIcon);
Vue.component("WarningIcon", WarningIcon);
Vue.component("PlusIcon", PlusIcon);
Vue.component("MinusIcon", MinusIcon);
Vue.component("Stock", Stock);
Vue.component("Clear", Clear);
Vue.component("RadioInput", RadioInput);
