






import { Component, Vue } from "vue-property-decorator";
import Designer from "@/views/Designer.vue";
import Header from "@/components/designer/header/Header.vue";
@Component({
  components: {
    Designer,
    Header
  }
})
export default class App extends Vue {}
