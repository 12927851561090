



















































import { Component, Watch } from "vue-property-decorator";
import Header from "@/components/designer/header/Header.vue";
import Configurator from "@/components/designer/Configurator.vue";
import Menu from "@/components/designer/header/Menu.vue";
import Footer from "@/components/designer/footer/Footer.vue";
import { mixins } from "vue-class-component";
import { LayoutStore, ShapeStore } from "@/mixins/store";
import { Auth } from "@/mixins/auth";
import { FabricRules } from "@/mixins/business_logic/global/fabric_rules";
import { Roles } from "@/models/user/roles";
import ConfigService from "@/services/config_file_service";
import {
  APIError,
  AuthError,
  NotFoundError,
  PermissionsError
} from "@/services/error_service";
import { AttributeRules } from "@/mixins/business_logic/global/attribute_rules";
import { UmbrellaModel } from "@/models/products/umbrella";
import AuthService from "@/services/auth_service";
import DesignerIconMenu from "@/components/designer/icon_menu/DesignerIconMenu.vue";

@Component({
  components: {
    Configurator,
    Menu,
    Header,
    Footer,
    DesignerIconMenu
  }
})
export default class Designer extends mixins(
  LayoutStore,
  ShapeStore,
  Auth,
  FabricRules,
  AttributeRules
) {
  protected Roles = Roles;
  protected isViewerReady = false;
  protected isStockOnly = false;
  protected editMode = false;
  protected umbrellaName = "";
  protected authService = new AuthService();

  @Watch("fileBeingEditedId", { immediate: true, deep: true })
  onNewSaveFile(newValue: string, oldValue: string): void {
    if (this.fileBeingEditedId && newValue !== oldValue) {
      this.editMode = true;
      this.fetchFileName();
    }
  }

  /** Calls method in attribute_rules mixin */
  @Watch("umbrellaModel", { immediate: true, deep: true })
  protected onModelChange(
    newModel: UmbrellaModel,
    oldModel: UmbrellaModel
  ): void {
    this.checkModelAttributes();
  }

  /** Calls method in fabric_rules mixin */
  @Watch("ribFabric", {deep: true})
  protected onRibFabricChange(): void {
    this.ribFabricRules();
  }

  /** Calls method in fabric_rules mixin */
  @Watch("valance", {deep: true})
  protected onValanceChange(): void {
    this.valanceFabricRules();
  }

  /** Calls method in fabric_rules mixin */
  @Watch("canopyFabric", {deep: true})
  protected onCanopyFabricChange(): void {
    this.canopyFabricRules();
  }

  created() {
    if (this.$route.query.redirect) {
      this.checkAuth();
    }
  }

  mounted() {
    if (!this.collection || !this.umbrellaModel) {
      this.$router.push("/");
    }
    if (this.$route.query.quickship) {
      const loader = this.$loading.show({ opacity: 1 });
      setTimeout(() => {
        loader.hide();
      }, 3000);
    }
    if (
      this.isLoggedIn &&
      this.fileBeingEditedId &&
      Object.prototype.hasOwnProperty.call(this.$route.query, "edit")
    ) {
      this.setEditingUmbrellaMode();
    } else if (
      Object.prototype.hasOwnProperty.call(this.$route.query, "edit")
    ) {
      this.turnOffEditMode();
    } else {
      this.addFileBeingEditedId("");
      this.editMode = false;
    }
    document.body.classList.add('designerBody')
  }
  destroyed(): void {
    document.body.classList.remove('designerBody')
  }


  protected async tiltUmbrella(): Promise<void> {
    await this.tilt();
  }

  protected async checkAuth(): Promise<void> {
    try {
      const user: any = await this.authService.checkAuth();
      this.setLoggedIn(true);
      this.setUserRole(user.role);
    } catch (err) {
      this.setLoggedIn(false);
      this.setUserRole(null);
    }
  }

  protected turnOffEditMode(): void {
    this.$router.replace("/designer");
    this.addFileBeingEditedId("");
    this.editMode = false;
  }

  protected setEditingUmbrellaMode(): void {
    this.editMode = true;
    this.fetchFileName();
  }

  protected async fetchFileName(): Promise<void> {
    const configService = new ConfigService();
    try {
      const res = await configService.getFile(this.fileBeingEditedId);
      this.umbrellaName = res.name ? res.name : "";
    } catch (err) {
      if (err instanceof NotFoundError) {
        NotFoundError.popup(err.message, err.statusCode);
      } else if (err instanceof PermissionsError) {
        PermissionsError.popup(err.message, err.statusCode);
      } else if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        APIError.popup(err.message, err.statusCode);
      }
    }
  }

  protected isStock(isStockOnly: boolean): void {
    this.isStockOnly = isStockOnly;
  }

  protected ready(): void {
    this.isViewerReady = true;
    /** From mixin fabric_rules */
    this.initFabricRules();
  }
}


