var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bottom-info-bar"},[_c('div',{staticStyle:{"height":"30px"}},[_c('a',{staticClass:"prevnext prev",on:{"click":_vm.stepBack}},[_vm._v("\n      ‹ "),_c('span',[_vm._v(_vm._s(_vm.prevLabel))])]),_c('a',{staticClass:"prevnext next",on:{"click":_vm.stepForward}},[_c('span',[_vm._v(_vm._s(_vm.nextLabel))]),_vm._v("\n      ›\n    ")])]),_vm._v(" "),_c('div',{staticClass:"uk-grid"},[_c('div',{staticClass:"umbrella-info"},[_c('div',[_c('h1',[_vm._v("\n          "+_vm._s(_vm.collection.display_name.indexOf(" ") != -1
              ? _vm.formatTitle(_vm.collection.display_name, "a")
              : _vm.collection.display_name)+"\n          "),_c('em',[_vm._v(_vm._s(_vm.collection.display_name.indexOf(" ") != -1
              ? _vm.formatTitle(_vm.collection.display_name, "b")
              : null))])])]),_vm._v(" "),_c('div',{staticClass:"modal"},[_c('p',{staticClass:"info-text"},[_vm._v("\n          "+_vm._s(_vm.umbrellaModel ? _vm.umbrellaModel.model : "NO MODEL CHOSEN")+"\n          ("+_vm._s(_vm.umbrellaModel.size[0])+" "+_vm._s(_vm.umbrellaModel.shape[0])+")\n        ")])])]),_vm._v(" "),_c('div',{staticClass:"umbrella-pricing uk-flex uk-flex-right"},[_c('div',[_c('div',{staticClass:"uk-flex uk-flex-row@xl uk-flex-column uk-flex-bottom@xl uk-flex-middle@xl uk-flex-right"},[_c('div',{staticClass:"uk-flex uk-flex-left@xl uk-flex-right uk-flex-bottom"},[(!_vm.isLoggedIn)?_c('p',{staticClass:"info-text"},[_c('a',{on:{"click":function($event){return _vm.$router.push('/login?return=designer')}}},[_vm._v("SIGN IN")]),_vm._v("\n              TO SEE PRICE\n            ")]):_c('p',{staticClass:"info-text"},[_vm._v("\n              "+_vm._s(_vm.preset !== _vm.Preset.S1 && _vm.preset !== _vm.Preset.S1DV
                  ? "CONTACT US FOR PRICE"
                  : _vm.isLoggedIn && _vm.userRole === _vm.Roles.Unverified
                  ? "CONTACT US FOR PRICE"
                  : _vm.isLoggedIn && _vm.userRole === _vm.Roles.Customer
                  ? "MSRP"
                  : "YOUR PRICE")+"\n            ")]),_vm._v(" "),_c('button',{staticClass:"icon-info uk-flex-none"},[_c('InfoIcon')],1),_vm._v(" "),_vm._m(0)])]),_vm._v(" "),_c('strong',[(_vm.isLoggedIn && _vm.userRole !== _vm.Roles.Unverified)?_c('Pricing'):_vm._e()],1),_vm._v(" "),_c('LeadTime')],1)])]),_vm._v(" "),_c('div',[_c('button',{staticClass:"button",on:{"click":_vm.getImagesQuote}},[_vm._v("\n      Request A Quote\n    ")]),_vm._v(" "),_c('Download'),_vm._v(" "),(_vm.isLoggedIn)?_c('span',{on:{"click":_vm.getImagesShare}},[(_vm.isLoggedIn)?_c('Share',{attrs:{"showDialog":_vm.showShareDialog,"shareLink":_vm.shareLink},on:{"close":_vm.closeShare}}):_vm._e()],1):_c('button',{staticClass:"button auth-required",attrs:{"disabled":"","uk-tooltip":"Please create an account to access this feature"}},[_vm._v("\n      SHARE\n    ")])],1),_vm._v(" "),_c('div',{staticClass:"quick-ship logged-out"},[(_vm.isLoggedIn && _vm.userRole !== _vm.Roles.Unverified)?_c('p',{staticClass:"qs"},[_vm._v("\n      Need it sooner? Check out our\n      "),_c('a',{on:{"click":function($event){return _vm.$router.push('/quickship')}}},[_c('em',[_vm._v("QUICK SHIP")])]),_vm._v("\n      availability.\n    ")]):_vm._e(),_vm._v(" "),(_vm.isLoggedIn && _vm.userRole !== _vm.Roles.Unverified)?_c('div',{staticClass:"qs-icon"},[_c('a',{attrs:{"uk-tooltip":"Save as a QuickShip item"},on:{"click":_vm.getImagesQuickShip}},[_c('img',{attrs:{"src":require("../../../assets/images/quickship_icon.png")}})])]):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"right tt",attrs:{"uk-dropdown":"animation: uk-animation-slide-top-small; delay-hide: 100; pos: top-right;"}},[_c('div',{staticClass:"dropdown-wrap"},[_c('p',[_vm._v("\n                  Prices shown are only an estimate. Actual pricing may\n                  differ.\n                ")])])])}]

export { render, staticRenderFns }