































import { Component, Watch } from "vue-property-decorator";
import Gallery from "@/components/gallery/Gallery.vue";
import CameraControls from "@/components/designer/icon_menu/CameraControls.vue";
import { mixins } from "vue-class-component";
import {
  APIError,
  AuthError,
  BadRequest,
  NotFoundError,
  PermissionsError
} from "@/services/error_service";
import { ConfigCreator } from "@/mixins/configurator";
import ConfigFileService from "@/services/config_file_service";
import SaveUmbrellaModal from "@/components/designer/icon_menu/SaveUmbrellaModal.vue";
import { Auth } from "@/mixins/auth";
import { ConfigFile } from "@/models/configurator/config_file";

@Component({
  components: {
    Gallery,
    CameraControls,
    SaveUmbrellaModal
  }
})
export default class SaveUmbrella extends mixins(ConfigCreator, Auth) {
  protected editMode = false;
  protected loader: any = null;
  protected configFileService = new ConfigFileService();
  protected dialog = false;

  @Watch("$route.query", { immediate: true })
  onNewSaveFile(): void {
    if (
      this.fileBeingEditedId &&
      Object.prototype.hasOwnProperty.call(this.$route.query, "edit")
    ) {
      this.editMode = true;
    }
  }

  protected async saveFileFlow(
    saveAsNew: boolean,
    images: string[],
    fileName: string
  ): Promise<void> {
    this.loader = this.$loading.show(
      { opacity: 0.5 },
      { before: this.$createElement("h1", "Saving...") }
    );
    const body = await this.transformDataToConfigFile();
    if (fileName) {
      body.name = fileName;
    }
    if (!saveAsNew) {
      this.saveExistingFile(body, images);
    } else {
      this.saveNewFile(body, images);
    }
  }

  protected async saveNewFile(
    body: ConfigFile,
    images: string[]
  ): Promise<void> {
    try {
      const res = await this.configFileService.createFile(body, images);
      this.addFileBeingEditedId(res._id!);
      this.loader.hide();
      this.loader = this.$loading.show(
        { opacity: 0.5 },
        { before: this.$createElement("h1", "Saved!") }
      );
      setTimeout(() => {
        this.loader.hide();
        if (!this.editMode) {
          this.$router.replace("/designer?edit");
        }
      }, 750);
    } catch (err) {
      this.loader.hide();
      if (err instanceof BadRequest) {
        BadRequest.popup(err.message, err.statusCode);
      } else if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        APIError.popup(err.message, err.statusCode);
      }
    }
  }

  protected async saveExistingFile(
    body: ConfigFile,
    images: string[]
  ): Promise<void> {
    try {
      const res = await this.configFileService.updateFile(
        body,
        this.fileBeingEditedId,
        images
      );
      this.addFileBeingEditedId(res._id!);
      this.loader.hide();
      this.loader = this.$loading.show(
        { opacity: 0.5 },
        { before: this.$createElement("h1", "Saved!") }
      );
      this.editMode = true;
      setTimeout(() => {
        this.loader.hide();
      }, 750);
    } catch (err) {
      this.loader.hide();
      if (err instanceof BadRequest) {
        BadRequest.popup(err.message, err.statusCode);
      } else if (err instanceof NotFoundError) {
        NotFoundError.popup(err.message, err.statusCode);
      } else if (err instanceof AuthError) {
        AuthError.logout();
      } else if (err instanceof PermissionsError) {
        PermissionsError.popup(err.message, err.statusCode);
      } else {
        APIError.popup(err.message, err.statusCode);
      }
    }
  }

  protected confirmSave(
    saveAsNew: boolean,
    images: string[],
    fileName?: string
  ): void {
    this.dialog = false;
    this.saveFileFlow(saveAsNew, images, fileName ? fileName : "");
  }
}
