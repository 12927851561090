import { render, staticRenderFns } from "./SaveUmbrella.vue?vue&type=template&id=191ceab0&scoped=true&"
import script from "./SaveUmbrella.vue?vue&type=script&lang=ts&"
export * from "./SaveUmbrella.vue?vue&type=script&lang=ts&"
import style0 from "./SaveUmbrella.vue?vue&type=style&index=0&id=191ceab0&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "191ceab0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDialog } from 'vuetify/lib/components/VDialog';
installComponents(component, {VDialog})
