import * as fabric from "@/models/products/fabric";

import axios, { AxiosResponse } from "axios";
import { APIError, NotFoundError } from "./error_service";

/**
 * Creates a new Fabric Service

 * @example
 * const fabricService = new FabricService();
 * fabricService.getFabrics();
 */

axios.defaults.headers.apikey = process.env.VUE_APP_API_KEY;

export default class FabricService {
  /**
   * @param {object} optionsObject optional filters, grade & mfr
   * @returns {FabricSwartch[]} - response array of fabric swatches
   */
  public async getFabrics(optionsObject?: {
    grade?: fabric.FabricGrade;
    mfr?: fabric.FabricMfr;
  }): Promise<fabric.FabricSwatch[]> {
    let fabrics;
    let url = process.env.VUE_APP_API_NODE_URL + "/fabrics";
    if (optionsObject) {
      let counter = 0;
      let char = "?";
      if (optionsObject.grade) {
        counter++;
        if (counter > 1) {
          char = "&";
        }
        url += char + "grade=" + optionsObject.grade;
      }
      if (optionsObject.mfr) {
        counter++;
        if (counter > 1) {
          char = "&";
        }
        url += char + "mfr=" + optionsObject.mfr;
      }
    }
    try {
      const options = {
        withCredentials: true
      };
      const res: AxiosResponse = await axios.get(url);
      fabrics = res.data.data;
    } catch (err) {
      throw new APIError(
        "Unable to get fabric data. Please try again.",
        err.response.status
      );
    }
    return fabrics;
  }

  /**
   * @param {object}
   * @returns {Fabric} - response object of requested fabric
   */
  public async getSingleFabric(
    mfr_code: number | string
  ): Promise<fabric.FabricSwatch> {
    let fabric;
    const url = process.env.VUE_APP_API_NODE_URL + "/fabrics/" + mfr_code;
    try {
      const options = {
        withCredentials: true
      };
      const res: AxiosResponse = await axios.get(url);
      fabric = res.data.data;
    } catch (err) {
      if (err.response && err.response.status === 404) {
        throw new NotFoundError(
          "Fabric swatch not found.",
          err.response.status
        );
      } else {
        throw new APIError(
          "Something went wrong, please try again.",
          err.response.status
        );
      }
    }
    return fabric;
  }
}
