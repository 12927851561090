




























import { Component, Watch, Prop } from "vue-property-decorator";
import {
  BaseStore,
  CanopyStore,
  FrameStore,
  LayoutStore,
  ShapeStore
} from "@/mixins/store";
import { mixins } from "vue-class-component";
import ProductService from "@/services/product_service";
import { Auth } from "@/mixins/auth";
import { AuthError, GenericError } from "@/services/error_service";
import { Preset } from "@/models/configurator/configurator_options";
import PricingBreakdown from "@/components/order_form/PricingBreakdown.vue";

/** SEE PRICING DIAGRAM FOR MORE INFORMATION */
@Component({
  components: {
    PricingBreakdown
  }
})
export default class Pricing extends mixins(
  ShapeStore,
  CanopyStore,
  BaseStore,
  LayoutStore,
  FrameStore,
  Auth
) {
  @Prop({ default: 1 }) qty!: number;
  protected productService = new ProductService();
  protected showPriceModal = false;
  protected itemPrice = { base: 0, umbrella: 0 };
  protected Preset = Preset;

  protected toggleModal(): void {
    this.showPriceModal = true;
  }

  @Watch("preset", { immediate: true, deep: true })
  protected async updatePreset(): Promise<void> {
    this.updatePrice();
  }

  @Watch("umbrellaModel", { immediate: true, deep: true })
  protected updateUmbrella(): void {
    this.updatePrice();
  }

  @Watch("baseModel", { immediate: true, deep: true })
  protected updateBase(): void {
    this.updatePrice();
  }

  @Watch("allCanopyData", { immediate: true, deep: true })
  protected updateCanopy(): void {
    this.updatePrice();
  }

  @Watch("valanceFabric", { immediate: true, deep: true })
  protected updateValance(): void {
    this.updatePrice();
  }

  async updatePrice(): Promise<void> {
    try {
      this.itemPrice = await this.productService.getTotal();
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      }
      GenericError.popup(err.message);
    }
  }

  protected close(): void {
    this.showPriceModal = false;
  }

  get price(): string {
    const numberPrice =
      this.itemPrice.base + this.itemPrice.umbrella * this.qty;
    return (Math.round(numberPrice * 100) / 100).toFixed(2);
  }

  get umbrellaPrice(): string {
    const numberPrice = this.itemPrice.umbrella;
    return (Math.round(numberPrice * 100) / 100).toFixed(2);
  }

  get basePrice(): string {
    const numberPrice = this.itemPrice.base;
    return (Math.round(numberPrice * 100) / 100).toFixed(2);
  }
}
